


































import './styles/ProjectsHeader.scss';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IAllUsersUser, IUserApp, IUserProjectsState, IUserProjectsView } from '@store/modules/user-projects/Interfaces';
import { UserProjectsMutations } from '@store/modules/user-projects/Types';
import { userNamespace } from '@store/modules/user';
import { IUserState } from '@store/modules/user/Interfaces';
import { IViewMode } from "@/interfaces/IViewMode";

const NSUserProjects = namespace('storeUserProjects');

@Component({
    name: 'ProjectsHeader',

    components: {
        BaseViewMode: () => import('@components/BaseViewMode/BaseViewMode.vue'),
        BaseViewToggler: () => import('@components/BaseViewToggler'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        PopupFiltersProjects: () => import('@components/popups/PopupFiltersProjects.vue'),
        FiltersPreview: () => import('@components/FiltersPreview/FiltersPreview.vue'),
    },
})
export default class ProjectsHeader extends Vue {
    $refs!: {
        filterPopup: HTMLFormElement,
        filtersPreview: HTMLFormElement,
    }

    @Prop({}) projectsByUsers!: Map<IUserApp, IAllUsersUser>;
    @Prop({}) filteredUsersCount!: number;

    @userNamespace.Getter('userData') userData!: IUserState;
    @NSUserProjects.State((state: IUserProjectsState) => state.views) views!: IUserProjectsView[];
    @NSUserProjects.State((state: IUserProjectsState) => state.currentView) currentView!: 'active' | 'archive' | 'users';
    @NSUserProjects.State((state: IUserProjectsState) => state.viewsMode) viewsMode!: IViewMode[];
    @NSUserProjects.Mutation(UserProjectsMutations.M_CHANGE_VIEW)
    changeView!: (view: IUserProjectsView) => void;
    @NSUserProjects.Mutation(UserProjectsMutations.M_CHANGE_VIEW_MODE)
    changeViewMode!: (viewMode: string) => void;

    created() {
        this.applyViewRouteParameter();
    }

    mounted() {
        this.resetFilters();
    }

    get filteredViews() {
        if (this.userData.userInfo && !this.userData.userInfo.portalPart) {
            return this.views.filter((item) => item.value === 'active' || item.value === 'archive');
        }
        return this.views;
    }

    get isViewRouteParameterExists() {
        return 'view' in this.$route.params;
    }

    applyViewRouteParameter() {
        if (this.isViewRouteParameterExists) {
            const viewFromRoute = this.views.find((view) => view.value === this.$route.params.view)
            this.changeView(viewFromRoute ?? this.views[0]);
        }
    }

    openFilters() {
        this.$refs.filterPopup.openPopup();
    }

    setFiltersPreview(filtersData) {
        this.$refs.filtersPreview.setFiltersData(filtersData);
    }

    resetFiltersPreview(data) {
        this.$refs.filterPopup.changeSelected(data, true);
    }

    resetFilters() {
        if (this.$refs.filterPopup) {
            this.$refs.filterPopup.cancelFilters();
        }
    }

    changeViewModeEvent(viewMode) {
        this.changeViewMode(viewMode);
    }
}
